import React from 'react';
import moment from 'moment';
import 'antd/dist/antd.css';
import { useMutation, QueryClientProvider, QueryClient } from 'react-query';
import './App.css';
import { Input, Result } from 'antd';
import axiosConfig from "./axiosConfig";
const { Search } = Input;


const verifyCertificate = async (certificateId) => {
    const data = await axiosConfig.request({
        method: "post",
        url: `/verify`,
        data: { "security_code": certificateId }
    });
    return data
};

function AppWrapper() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <span
                style={{
                    display: 'table',
                    margin: '0 auto',
                    marginTop: '2em',
                    fontSize: '2em'
                }}
            > Verify Jacaranda Health EmONC Certificate</span>
            <App/>
        </QueryClientProvider>
    )
}

function App() {
    const mutation = useMutation(certificate => verifyCertificate(certificate))

    const onSearch = async (value) => {
       if(value === ""){
           return
       }
       mutation.mutate(value)
    }

    return (
        <>
            <SearchComponent onSearch={onSearch}/>
            {
                mutation.isLoading ?
                    <span className="result"> Loading..</span>: (
                        <>
                            {
                                mutation.isError ? (
                                    <Result
                                        className="error"
                                        status="404"
                                        title="404"
                                        subTitle="Sorry, the certificate does not exist"
                                    />
                                ): null
                            }
                            {
                                mutation.data ?
                                    <Result
                                        className="result"
                                        status="success"
                                        title={mutation?.data?.data?.certificate?.module_name}
                                        subTitle={mutation?.data?.data?.certificate?.username}
                                        extra={[
                                            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}> Completion Date: {
                                                moment(
                                                    mutation?.data?.data?.certificate?.completion_date
                                                ).format('YYYY-MM-DD')}</span>
                                        ]}
                                    />: null
                            }
                        </>
                    )
            }
            </>

    )
}


function SearchComponent({ onSearch }) {
    return (
        <Search
            className="search"
            placeholder="Enter certificate security code"
            allowClear
            enterButton="Search"
            size="medium"
            onSearch={onSearch}
            style={{ width: 300 }}
        />
  )
}

export default AppWrapper;
